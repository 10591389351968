import { Col, Drawer, Dropdown, Icon, Menu, Modal, Row, Tag, Tooltip } from 'antd';
import firebase from 'firebase';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Highlighter from 'react-highlight-words';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IListPropsTypes, IProject } from '../../types/projectTypes';
import { defaultTag, getProjectImage, mobileCheck, trackAnalytics } from '../../utils';
import debounce from '../../utils/debounce';
import CreateJiraIssueForm from '../jira-integration/CreateIssueForm';
import LeftArrow from '../layout/assects/next.png';
import Spinner from '../shared/spinner';
import SubscriptionPlan from '../subscription-plan';
import CreateTrelloIssueForm from '../trello-integration/CreateIssueForm';
import { WelcomeScreen } from '../welcome';
import ProjectTile from './project-tile';
import ProjectForm from './ProjectForm';
import ProjectShareForm from './ProjectShareForm';
import './style.css';

interface IProjectListState {
  searchKeyword: string;
  showEdit: boolean;
  showShare: boolean;
  project: {
    id: string;
    project_url: string;
    title: string;
    member_emails: string[];
    type: string;
    project_created_by: string;
    imageType: string;
    jiraIssueUrl: string;
    trelloIssueUrl: string;
    project_tags: any;
    imagebase64?: any;
    imageUrl?: any;
    thumbnail_url?: any;
  };
  projects: IProject[];
  searchProjects: IProject[];
  createJiraIssueVisible: boolean;
  createTrelloIssueVisible: boolean;
  welcomeScreenVisible: boolean;
  subscriptionDrawerVisible: boolean;
  showArrow: boolean;
  loader: boolean;
  limit: number;
  isNotGetDataAfter3Sec: boolean;
}
const isMobile = mobileCheck();
class ProjectList extends Component<RouteComponentProps & IListPropsTypes, IProjectListState> {
  constructor(props: any) {
    super(props);
    const { loader } = props;
    this.state = {
      subscriptionDrawerVisible: false,
      showEdit: false,
      showShare: false,
      project: {
        id: '',
        project_url: '',
        title: '',
        member_emails: [],
        project_tags: [],
        type: 'liveWebsite',
        project_created_by: '',
        imageType: 'computer',
        jiraIssueUrl: '',
        trelloIssueUrl: '',
      },
      searchKeyword: '',
      projects: [],
      searchProjects: [],
      createJiraIssueVisible: false,
      createTrelloIssueVisible: false,
      welcomeScreenVisible: false,
      showArrow: false,
      loader,
      limit: 25,
      isNotGetDataAfter3Sec: false,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    const { projects, match } = nextProps;

    if (projects !== this.props.projects || this.props.match !== match) {
      this.setState({ projects, searchProjects: projects, searchKeyword: '' });
    }

    if (
      nextProps.profile &&
      !nextProps.profile.isEmpty &&
      nextProps.profile !== this.props.profile
    ) {
      this.setState({
        welcomeScreenVisible: nextProps.profile.isWelcomeScreenVisited ? false : true,
      });
    }

    if (nextProps.loader !== this.props.loader) {
      this.setState({
        loader: nextProps.loader,
      });
    }
  }

  shareClicked = (e: any, projectDetail: any, menuItem?: boolean) => {
    !menuItem && e.stopPropagation();
    this.setState({
      showShare: true,
      project: {
        id: projectDetail.id,
        project_url: projectDetail.project_url,
        title: projectDetail.title,
        member_emails: projectDetail.member_emails,
        type: projectDetail.type,
        project_tags: projectDetail.project_tags,
        project_created_by: projectDetail.project_created_by,
        imageType: projectDetail.imageType,
        jiraIssueUrl: projectDetail.jiraIssueUrl || '',
        trelloIssueUrl: projectDetail.trelloIssueUrl || '',
        imagebase64: projectDetail.project_base64_image,
        imageUrl: projectDetail.imageUrl || '',
        thumbnail_url: projectDetail.thumbnail_url || '',
      },
    });
    trackAnalytics('Project List', 'Share clicked', '');
  };

  clearSearch = () => {
    this.setState({
      searchKeyword: '',
      searchProjects: this.state.projects,
    });
  };

  showEdit = (param: boolean) => {
    this.setState({ showEdit: param });
  };

  showShare = (param: boolean) => {
    this.setState({ showShare: param });
  };

  setJiraCreateIssueVisible = () => {
    this.setState({
      createJiraIssueVisible: false,
    });
  };

  handleJiraCreateIssueSubmit = () => {
    const { createJiraIssueVisible } = this.state;
    this.setState({
      createJiraIssueVisible: !createJiraIssueVisible,
    });
  };

  setTrelloCreateIssueVisible = () => {
    this.setState({
      createTrelloIssueVisible: false,
    });
  };

  setWelcomeScreenVisible = () => {
    this.updateUser({
      isWelcomeScreenVisited: true,
    });
    this.setState({
      welcomeScreenVisible: false,
    });
  };

  handleTrelloCreateIssueSubmit = () => {
    const { createTrelloIssueVisible } = this.state;
    this.setState({
      createTrelloIssueVisible: !createTrelloIssueVisible,
    });
  };

  showDelete = (project: any, onDelete: (o: string) => void, e: any, isMenu?: boolean) => {
    !isMenu && e.stopPropagation();
    let isDelete = true;
    const { loginUserId, loginUserEmail } = this.props;
    if (project.project_created_by !== loginUserId) {
      isDelete = false;
    }
    trackAnalytics('Project List', 'Feedback delete click');
    Modal.confirm({
      content: '',
      title: isDelete
        ? 'Do you want to delete this canvas?'
        : 'Are you sure, you want to unfollow this canvas?',
      onOk() {
        trackAnalytics('Project List', 'Deleted a feedback');
        if (isDelete) {
          onDelete(project.id);
        } else {
          const projectData = { ...project };
          if (projectData && projectData.member_emails) {
            let memberEmails = projectData.member_emails || [];
            memberEmails = memberEmails.filter((email: string) => email !== loginUserEmail);
            projectData.member_emails = memberEmails;
            const firestore = firebase.firestore();
            firestore
              .collection('projects')
              .doc(project.id)
              .update({ ...projectData });
          }
        }
      },
    });
  };

  openTrelloClicked = (e: any, projectDetail: any, menuItem?: boolean) => {
    !menuItem && e.stopPropagation();
    if (projectDetail.trelloIssueUrl) {
      window.open(projectDetail.trelloIssueUrl, '_blank');
      trackAnalytics('Project List', 'Open trello ticket clicked', '');
    } else {
      this.setState({
        createTrelloIssueVisible: true,
        project: {
          id: projectDetail.id,
          project_url: projectDetail.project_url,
          title: projectDetail.title,
          member_emails: projectDetail.member_emails,
          project_tags: projectDetail.project_tags,
          type: projectDetail.type,
          project_created_by: projectDetail.project_created_by,
          imageType: projectDetail.imageType,
          jiraIssueUrl: projectDetail.jiraIssueUrl || '',
          trelloIssueUrl: projectDetail.trelloIssueUrl || '',
        },
      });
      trackAnalytics('Project List', 'Export to trello clicked', '');
    }
  };

  openEditClicked = (e: any, projectDetail: any, menuItem?: boolean) => {
    !menuItem && e.stopPropagation();
    this.setState({
      showEdit: true,
      project: {
        id: projectDetail.id,
        project_url: projectDetail.project_url,
        title: projectDetail.title,
        member_emails: projectDetail.member_emails,
        project_tags:
          projectDetail.project_tags && Array.isArray(projectDetail.project_tags)
            ? projectDetail.project_tags
            : [],
        type: projectDetail.type,
        project_created_by: projectDetail.project_created_by,
        imageType: projectDetail.imageType,
        jiraIssueUrl: projectDetail.jiraIssueUrl || '',
        trelloIssueUrl: projectDetail.trelloIssueUrl || '',
      },
    });
    trackAnalytics('Project List', 'Edit project clicked', '');
  };

  openJiraClicked = (e: any, projectDetail: any, menuItem?: boolean) => {
    !menuItem && e.stopPropagation();
    if (projectDetail.jiraIssueUrl) {
      window.open(projectDetail.jiraIssueUrl, '_blank');
      trackAnalytics('Project List', 'Open jira ticket clicked', '');
    } else {
      this.setState({
        createJiraIssueVisible: true,
        project: {
          id: projectDetail.id,
          project_url: projectDetail.project_url,
          title: projectDetail.title,
          member_emails: projectDetail.member_emails,
          project_tags: projectDetail.project_tags,
          type: projectDetail.type,
          project_created_by: projectDetail.project_created_by,
          imageType: projectDetail.imageType,
          jiraIssueUrl: projectDetail.jiraIssueUrl || '',
          trelloIssueUrl: projectDetail.trelloIssueUrl || '',
        },
      });
      trackAnalytics('Project List', 'Export to jira clicked', '');
    }
  };

  handleSearchInputChange = (e: any) => {
    const value = e.target.value;
    this.setState({
      searchKeyword: value,
    });
    const projects = value ? this.commentSearch(value) : this.state.projects;
    const debouncedCaller: any = debounce(
      () => trackAnalytics('Project list', 'User searched', value),
      500,
    );
    debouncedCaller('Project list', 'User searched', value);
    this.setState({
      searchProjects: projects,
    });
  };

  handleProjectUrl = (imageUrl: string) => {
    this.setState({
      project: {
        ...this.state.project,
        imageUrl,
      },
    });
  };

  commentSearch = (value: string) => {
    const { projects } = this.state;
    const searchTextArray: any = value.split(' ');
    return projects.filter((item: any) => {
      return searchTextArray.every((el: any) => {
        return (
          (item.type && item.type.toLowerCase().indexOf(el.toLowerCase()) > -1) ||
          (item.title && item.title.toLowerCase().indexOf(el.toLowerCase()) > -1) ||
          (item.project_url && item.project_url.toLowerCase().indexOf(el.toLowerCase()) > -1) ||
          (item.project_tags &&
            item.project_tags.find(
              (tagvalue: string) => tagvalue.toLowerCase().indexOf(el.toLowerCase()) > -1,
            ))
        );
      });
    });
  };

  getProjectDetail = (projectDetail: any) => {
    if (projectDetail.type === 'audio') {
      return `/audio/${projectDetail.id}`;
    } else if (
      projectDetail.type === 'image' ||
      projectDetail.project_url.match(/\.(jpeg|jpg|gif|png)$/) != null ||
      projectDetail.project_url.includes('?alt=media')
    ) {
      return `/project/${projectDetail.id}?recalc=true`;
    } else if (projectDetail.type === 'whiteboard') {
      return `/whiteboard/${projectDetail.id}`;
    } else if (projectDetail.type === 'website') {
      return `/website/${projectDetail.id}`;
    } else if (projectDetail.type === 'text') {
      return `/text/${projectDetail.id}`;
    } else if (projectDetail.type === 'text') {
      return `/image/${projectDetail.id}`;
    }
    return '/project/' + projectDetail.id;
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.profile && !nextProps.profile.isEmpty && this.state.searchProjects.length) {
      if (!(nextProps.profile.preferences && nextProps.profile.preferences.seenListOnce)) {
        this.setState({
          showArrow: true,
        });
        const firestore = firebase.firestore();
        firestore
          .collection('users')
          .doc(nextProps.authId)
          .set(
            {
              preferences: {
                seenListOnce: true,
              },
            },
            { merge: true },
          );
      }
    }

    let timeout;
    if (nextProps.canvases.length <= nextProps.paginationLimit) {
      if (nextProps.canvases.length) {
        timeout && clearTimeout(timeout);
        if (this.state.isNotGetDataAfter3Sec) {
          this.setState({
            isNotGetDataAfter3Sec: false,
          });
        }
      } else if (!this.props.canvases.length) {
        timeout = setTimeout(() => {
          this.setState({
            isNotGetDataAfter3Sec: true,
          });
        }, 3000);
      }
    }
  }

  updateUser = (updateData: any) => {
    const uid = this.props.authId;
    if (uid) {
      const firestore = firebase.firestore();
      firestore
        .collection('users')
        .doc(uid)
        .set(updateData, { merge: true });
    }
  };

  render() {
    const {
      showEdit,
      project,
      projects,
      searchKeyword,
      searchProjects,
      showShare,
      createJiraIssueVisible,
      createTrelloIssueVisible,
      welcomeScreenVisible,
      subscriptionDrawerVisible,
      loader,
      isNotGetDataAfter3Sec,
    } = this.state;
    const {
      loginUserId,
      // profile,
      // signUpDays,
      history,
      loadingMoreCanvas,
      isMoreCanvas,
      loadMoreCanvas,
      canvases = [],
      selectedWorkflowId,
      isKanbanView = false,
      internalScroll,
      selectedMasterProject,
      isShowInitialStep,
      selectedOrganizationData,
    } = this.props;
    // const { plan = '', isFreePlan = true } = profile;
    if (loader) {
      // return null;
      return <Spinner />;
    }
    const projectsLength = searchProjects.length;

    return (
      <>
        <Drawer
          title='Subscription'
          placement='right'
          closable={false}
          onClose={() => {
            this.setState({
              subscriptionDrawerVisible: false,
            });
          }}
          visible={subscriptionDrawerVisible}
          className={'sidebar-team-member-drawer'}
        >
          <SubscriptionPlan />
        </Drawer>
        {/* {isFreePlan && signUpDays !== -1 && (plan === 'productTeam' || plan === 'digitalAgency') && (
          <div className='limit-expired'>
            {signUpDays <= 14 && (
              <div className='limit-expired-msg'>
                You have {14 - signUpDays} days remaining of free ({plan}) trial. Please{' '}
                <a
                  href='#'
                  onClick={() => {
                    this.setState({
                      subscriptionDrawerVisible: true,
                    });
                  }}
                >
                  upgrade
                </a>{' '}
                your plan to create a new canvas/comment.
              </div>
            )}
            {(!profile.created_at || signUpDays > 14) && (
              <div>
                Your free plan days has been completed. Please{' '}
                <a
                  href='#'
                  onClick={() => {
                    this.setState({
                      subscriptionDrawerVisible: true,
                    });
                  }}
                >
                  upgrade
                </a>{' '}
                your plan to create a new canvas/comment.
              </div>
            )}
          </div>
        )} */}
        <div className='project-list section'>
          {!isShowInitialStep && (
            <>
              {projects.length ? (
                <Row>
                  {this.props.match.path === '/old' && (
                    <div className='project-count'>
                      <span>
                        {projectsLength > 0 &&
                          `${projectsLength} Canvas${projectsLength === 1 ? '' : 'es'}`}
                      </span>
                    </div>
                  )}
                  {this.props.match.path === '/old' && (
                    <div className='input-search-comment-container project-search'>
                      <input
                        autoFocus
                        value={searchKeyword}
                        onChange={this.handleSearchInputChange}
                        className='input-search-comment-field'
                        placeholder='Type to search'
                      />
                      {!searchKeyword.length ? (
                        <Icon className={'input-search-comment-icon'} type='search' />
                      ) : (
                        <Icon
                          onClick={this.clearSearch}
                          className={'input-search-comment-icon'}
                          type='close'
                        />
                      )}
                    </div>
                  )}
                </Row>
              ) : null}
              {isNotGetDataAfter3Sec && !canvases.length && !searchProjects.length ? (
                <div
                  className={
                    !searchProjects.length && searchKeyword
                      ? 'projects-no-found'
                      : 'project-not-created'
                  }
                >
                  {searchKeyword ? (
                    'No canvas found!'
                  ) : !isKanbanView ? (
                    isMobile ? (
                      <span>
                        No Canvas Found! <br />
                        Create one from New Canvas drop down.
                      </span>
                    ) : (
                      'No Canvas Found! Create one from New Canvas drop down.'
                    )
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <>
                  {
                    <div
                      className={
                        this.props.match.path === '/pk/:id'
                          ? 'kanvan-dashboard-project-list'
                          : 'default-dashboard-project-list'
                      }
                    >
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={() => {
                          trackAnalytics('Canvas', 'Load More canvas called');
                          loadMoreCanvas(selectedWorkflowId);
                        }}
                        hasMore={!loadingMoreCanvas && isMoreCanvas}
                        loader={<div className='loader'></div>}
                        useWindow={false}
                        threshold={350}
                      >
                        {canvases.map((projectDetail: IProject, index: number) => {
                          return (
                            <Fragment key={projectDetail.id}>
                              {internalScroll ? (
                                <Draggable
                                  draggableId={projectDetail.id}
                                  key={projectDetail.id}
                                  index={index}
                                >
                                  {(dragProvided, dragSnapshot) => (
                                    <ProjectTile
                                      index={index}
                                      projectDetail={projectDetail}
                                      history={history}
                                      masterProjectName={selectedMasterProject.name}
                                      isKanbanView
                                      provided={dragProvided}
                                      snapshot={dragSnapshot}
                                      internalScroll
                                    />
                                  )}
                                </Draggable>
                              ) : (
                                <ProjectTile
                                  key={index}
                                  index={index}
                                  projectDetail={projectDetail}
                                  history={history}
                                  masterProjectName={selectedMasterProject.name}
                                  isKanbanView
                                />
                              )}
                            </Fragment>
                          );
                        })}
                      </InfiniteScroll>
                      {loadingMoreCanvas && canvases.length ? (
                        <div className='loader custom-skeleton-container'>
                          <Spinner />
                        </div>
                      ) : null}
                    </div>
                  }

                  {/* <ProjectTile /> */}
                  {this.props.match.path === '/old' && (
                    <Row className={'project-parent'}>
                      {searchProjects.map((projectDetail: IProject, index: number) => {
                        const { createdAt } = projectDetail;
                        const date = moment.unix(createdAt.seconds).fromNow() || '';
                        let dateFormat = 'MMM Do [at] h:mm:ss A z';
                        if (moment.unix(createdAt.seconds).years() !== new Date().getFullYear()) {
                          dateFormat = 'MMM Do, YYYY [at] h:mm:ss A z';
                        }
                        const dateToolTip = moment.unix(createdAt.seconds).format(dateFormat) || '';
                        return (
                          <Col
                            className='project-image-col'
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}
                          >
                            <div className='project-image-container'>
                              <div className='project-image-div'>
                                <div
                                  className='project-preview-container'
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    this.props.history.push(this.getProjectDetail(projectDetail));
                                  }}
                                >
                                  {!mobileCheck() && (
                                    <>
                                      <Link
                                        to={this.getProjectDetail(projectDetail)}
                                        key={projectDetail.id}
                                        style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                                        className='open-board'
                                      >
                                        Click here to Open
                                      </Link>
                                      <div className='project-action'>
                                        <Tooltip title='Share'>
                                          <Icon
                                            className={'project-action-icon'}
                                            type='share-alt'
                                            onClick={(e: any) =>
                                              this.shareClicked(e, projectDetail)
                                            }
                                          />
                                        </Tooltip>

                                        <Tooltip
                                          title={`${
                                            projectDetail.jiraIssueUrl
                                              ? 'Open jira ticket'
                                              : 'Export jira ticket'
                                          }`}
                                        >
                                          <Icon
                                            className={'project-action-icon'}
                                            type='export'
                                            onClick={(e: any) =>
                                              this.openJiraClicked(e, projectDetail)
                                            }
                                          />
                                        </Tooltip>

                                        <Tooltip
                                          title={`${
                                            projectDetail.trelloIssueUrl
                                              ? 'Open trello ticket'
                                              : 'Export trello ticket'
                                          }`}
                                        >
                                          <Icon
                                            className={'project-action-icon'}
                                            type='export'
                                            onClick={(e: any) =>
                                              this.openTrelloClicked(e, projectDetail)
                                            }
                                          />
                                        </Tooltip>

                                        <Tooltip title='Edit'>
                                          <Icon
                                            type='edit'
                                            className={'project-action-icon'}
                                            onClick={(e: any) =>
                                              this.openEditClicked(e, projectDetail)
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                          <Icon
                                            className={'project-action-icon'}
                                            type='delete'
                                            onClick={this.showDelete.bind(
                                              this,
                                              projectDetail,
                                              this.props.onDelete,
                                            )}
                                          />
                                        </Tooltip>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {getProjectImage(projectDetail)}
                              </div>
                              <div className='project-list-details'>
                                <div className='project-desc word-wrap'>
                                  {projectDetail && projectDetail.type === 'liveWebsite' && (
                                    <div className='project-title'>
                                      {
                                        <Highlighter
                                          highlightClassName='matched-comment-highlight'
                                          searchWords={searchKeyword.split(' ')}
                                          autoEscape={true}
                                          textToHighlight={
                                            projectDetail.project_url.slice(0, 40) +
                                            (projectDetail.project_url.length > 40 ? '...' : '')
                                          }
                                        />
                                      }
                                    </div>
                                  )}
                                  <div className='project-type'>
                                    <Highlighter
                                      highlightClassName='matched-comment-highlight'
                                      searchWords={searchKeyword.split(' ')}
                                      autoEscape={true}
                                      textToHighlight={
                                        projectDetail.title
                                          ? projectDetail.title.slice(0, 40) +
                                            (projectDetail.title.length > 40 ? '...' : '')
                                          : 'Live website'
                                      }
                                    />
                                    {mobileCheck() && (
                                      <div style={{ position: 'absolute', right: 20 }}>
                                        <Dropdown
                                          overlay={
                                            <Menu>
                                              <Menu.Item>
                                                <div
                                                  onClick={(e: any) =>
                                                    this.shareClicked(e, projectDetail, true)
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      'menu-item-project-list project-action-icon'
                                                    }
                                                    type='share-alt'
                                                  />
                                                  Share
                                                </div>
                                              </Menu.Item>

                                              <Menu.Item>
                                                <div
                                                  onClick={(e: any) =>
                                                    this.openJiraClicked(e, projectDetail, true)
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      'menu-item-project-list project-action-icon'
                                                    }
                                                    type='export'
                                                  />
                                                  {projectDetail.jiraIssueUrl
                                                    ? 'Open jira ticket'
                                                    : 'Export jira ticket'}
                                                </div>
                                              </Menu.Item>

                                              <Menu.Item>
                                                <div
                                                  onClick={(e: any) =>
                                                    this.openTrelloClicked(e, projectDetail, true)
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      'menu-item-project-list project-action-icon'
                                                    }
                                                    type='export'
                                                  />
                                                  <span>
                                                    {projectDetail.trelloIssueUrl
                                                      ? 'Open trello ticket'
                                                      : 'Export trello ticket'}
                                                  </span>
                                                </div>
                                              </Menu.Item>

                                              <Menu.Item>
                                                <div
                                                  onClick={(e: any) =>
                                                    this.openEditClicked(e, projectDetail, true)
                                                  }
                                                >
                                                  <Icon
                                                    type='edit'
                                                    className={
                                                      'menu-item-project-list project-action-icon'
                                                    }
                                                  />
                                                  Edit
                                                </div>
                                              </Menu.Item>
                                              <Menu.Item>
                                                <div
                                                  onClick={() =>
                                                    this.showDelete(
                                                      projectDetail,
                                                      this.props.onDelete,
                                                      this,
                                                      true,
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    className={
                                                      'menu-item-project-list project-action-icon'
                                                    }
                                                    type='delete'
                                                  />
                                                  {'Delete'}
                                                </div>
                                              </Menu.Item>
                                            </Menu>
                                          }
                                          placement='topRight'
                                        >
                                          <Icon type='more' />
                                        </Dropdown>
                                      </div>
                                    )}
                                  </div>
                                  <Tooltip placement='topLeft' title={dateToolTip}>
                                    <div
                                      style={{ fontSize: 10, color: '#989393' }}
                                      className='project-type'
                                    >
                                      {date}
                                    </div>
                                  </Tooltip>
                                </div>
                                {projectDetail &&
                                projectDetail.unreadFeedbackArray &&
                                projectDetail.unreadFeedbackArray.length ? (
                                  <div className='project-feedback'>
                                    <div className='project-feedback-circle'>
                                      <Tooltip title='Unread Messages'>
                                        <div className='project-feedback-circle-count'>
                                          {projectDetail.unreadFeedbackArray.length
                                            ? projectDetail.unreadFeedbackArray.length
                                            : 0}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                ) : null}
                                {projectDetail &&
                                projectDetail.project_tags &&
                                projectDetail.project_tags.length ? (
                                  <div className='project-feedback context-menu'>
                                    <Tag color='#989393'>
                                      {defaultTag(projectDetail.type || 'liveWebsite')}
                                    </Tag>
                                    {Array.isArray(projectDetail.project_tags) &&
                                      projectDetail.project_tags.map((item: any) => (
                                        <Tag color='#989393'>{item}</Tag>
                                      ))}
                                  </div>
                                ) : (
                                  <div className='project-feedback'>
                                    <Tag color='#989393'>
                                      {defaultTag(projectDetail.type || 'liveWebsite')}
                                    </Tag>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                      {this.state.showArrow && (
                        <Col
                          style={{ display: 'flex', alignItems: 'center' }}
                          className='project-image-col'
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                        >
                          <div style={{ textAlign: 'left', marginLeft: '10px' }}>
                            <div className='bounce'>
                              <img
                                className='project-list-arrow'
                                style={{ width: '20px' }}
                                src={LeftArrow}
                                alt={'arrow'}
                              />
                            </div>
                            <div style={{ fontWeight: 600 }}>Click here</div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                </>
              )}
              <ProjectForm
                memberEMailEditable={project.project_created_by === loginUserId}
                title='Edit Canvas'
                visible={showEdit}
                project={project}
                setVisible={this.showEdit}
                edit
              />
              <ProjectShareForm
                title='Share Canvas'
                visible={showShare}
                project={project}
                setVisible={this.showShare}
                handleProjectUrl={this.handleProjectUrl}
              />
              <CreateJiraIssueForm
                title='Create Jira Issue'
                visible={createJiraIssueVisible}
                setVisible={this.setJiraCreateIssueVisible}
                handleSubmit={this.handleJiraCreateIssueSubmit}
                project={project}
                selectedOrganizationData={selectedOrganizationData}
              />
              <CreateTrelloIssueForm
                title='Create Trello Issue'
                visible={createTrelloIssueVisible}
                setVisible={this.setTrelloCreateIssueVisible}
                handleSubmit={this.handleTrelloCreateIssueSubmit}
                project={project}
                selectedOrganizationData={selectedOrganizationData}
              />
              {/* { For now, Removed welcome using false condition } */}
              {false && (
                <WelcomeScreen
                  title='Welcome to the world of productivity!'
                  visible={welcomeScreenVisible}
                  setVisible={this.setWelcomeScreenVisible}
                  updateUser={this.updateUser}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(ProjectList);